.margin-top-27 {
    margin-top: 27px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.post-bottom-border {
    border-style: none none solid;
    border-width: 0 0 1px;
    border-color: #D2D6DE;
}

.skin-blue .wrapper {
    background-color: #d2d6de !important;
}

.cursor-pointer {
    cursor: pointer;
}

.form-group .required .control-label:after {
    content: "*";
    color: #FF0000;
}


/* Landing Page Start */

.box-inner-padding-top {
    padding-top: 25px !important;
}

.skin-blue .main-header .logo {
    background-color: #FFFFFF !important;
}



/*Calendar Start*/

.fc-time {
    display: none !important;
}

.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
    color: #fff;
    text-decoration: none;
    text-align: center;
}



/*Calendar End*/


/* Landing Page End */


/* Main Body Start*/


/*.skin-blue .wrapper {
    background-color: #d2d6de !important;
}

.skin-blue .wrapper,
.skin-blue .main-sidebar,
.skin-blue .left-side {
    background-color: #cdcdcd;
}

.skin-blue .sidebar a {
    color: #333333;
}

.skin-blue .sidebar-menu>li>.treeview-menu {
    margin: 0 1px;
    background: #cdcdcd;
}

.skin-blue .sidebar-menu>li.header {
    color: #cdcdcd;
    background: #333333;
}

.skin-blue .user-panel>.info,
.skin-blue .user-panel>.info>a {
    color: #333333;
}

.skin-blue .treeview-menu>li>a {
    color: #333333;
}

.skin-blue .treeview-menu>li.active>a,
.skin-blue .treeview-menu>li>a:hover {
    color: #ffffff;
    background-color: #333333;
}

.skin-blue .main-header .navbar {
    background-color: #333333;
}

.skin-blue .main-header .logo {
    background-color: #252323;
    color: #ffffff;
    border-bottom: 0 solid transparent;
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
    background-color: #333333;
}

.skin-blue .main-header .logo:hover {
    background-color: #333333;
}

.skin-blue .main-header li.user-header {
    background-color: #333333;
}

ul.dropdown-menu.cust-dropdown-menu {
    border: 1px solid #333333 !important;
    border-radius: 1px !important;
    padding: 0 !important;
}

.box.box-info {
    border-top-color: #333333;
}

.box.box-primary {
    border-top-color: #333333;
}

.nav-tabs-custom>.nav-tabs>li.active {
    border-top-color: #333333;
}


*/


/* Main Body End*/


/* Start Select 2 Custom CSS */

.select2-container .select2-choice {
    -moz-user-select: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d2d6de;
    border-radius: 0;
    color: #444;
    display: block;
    height: 34px;
    line-height: 34px;
    overflow: hidden;
    padding: 0 0 0 8px;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
}

.select2-container .select2-choice .select2-arrow {
    background: #fff;
    border-left: none;
    border-radius: 0;
    display: inline-block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
}

.width-100 {
    width: 100%;
}






/* End Select 2 Custom CSS */


/* Start Set File Icon */

.pdf-icon-color {
    color: #999999;
}

.pdf-icon-color:hover {
    color: #CD1B00;
}

.tbl-col-width-1 {
    width: 1%;
}

.tbl-col-width-2 {
    width: 2%;
}

.tbl-col-width-3 {
    width: 3%;
}

.tbl-col-width-4 {
    width: 4%;
}

.tbl-col-width-5 {
    width: 5%;
}

.tbl-col-width-6 {
    width: 6%;
}

.tbl-col-width-7 {
    width: 7%;
}

.tbl-col-width-8 {
    width: 8%;
}

.tbl-col-width-9 {
    width: 9%;
}

.tbl-col-width-10 {
    width: 10%;
}

.tbl-col-width-11 {
    width: 11%;
}

.tbl-col-width-12 {
    width: 12%;
}

.tbl-col-width-13 {
    width: 13%;
}

.tbl-col-width-14 {
    width: 14%;
}

.tbl-col-width-15 {
    width: 15%;
}

.tbl-col-width-16 {
    width: 16%;
}

.tbl-col-width-17 {
    width: 17%;
}

.tbl-col-width-18 {
    width: 18%;
}

.tbl-col-width-19 {
    width: 19%;
}

.tbl-col-width-20 {
    width: 20%;
}

.tbl-col-width-21 {
    width: 21%;
}

.tbl-col-width-22 {
    width: 22%;
}

.tbl-col-width-23 {
    width: 23%;
}

.tbl-col-width-24 {
    width: 24%;
}

.tbl-col-width-25 {
    width: 25%;
}

.tbl-col-width-33 {
    width: 33%;
}

.tbl-col-width-34 {
    width: 34%;
}

.tbl-col-width-50 {
    width: 50%;
}

.tbl-col-width-60 {
    width: 60%;
}

.label-gray {
    background-color: #808080;
}

.label-brown {
    background-color: #A52A2A;
}

.label-chocolate {
    background-color: #D2691E;
}

.label-purple {
    background-color: #800080;
}

.label-teal {
    background-color: #008080;
}

.text-color-purple {
    color: #800080;
}

.text-color-green {
    color: #008000;
}

.table-tr-bg-color-green {
    background-color: #30d65b;
}






/* End Set File Icon */


/* Rack Shelves Start */


/*.cust-rack .cust-tr {
    color: #FFFFFF;
}

.cust-rack .td-empty {
    background-color: #006400;
}

.cust-rack .td-full {
    background-color: #CD6600;
}

.cust-rack .td-broken {
    background-color: #7a0000;
}

.cust-rack td:hover {
    background-color: #fef65b;
    color: #000000;
}

.rack-shipment:hover {
    font-weight: bold;
}

.btn-default {
    background-color: #eee !important;
}*/


/* Rack Shelves End */


/* Start Common CSS */


/*.cust-content {
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.cust-direct-chat-messages {
    height: auto !important;
}

.cursor-pointer {
    cursor: pointer;
}

.form-group .required .control-label:after {
    content: "*";
    color: #FF0000;
}

.view-details .cust-table {
    margin-bottom: 0 !important;
}

.cust-flag {
    position: absolute;
    margin-top: -6px;
}

.tab-pane .post-header {
    margin-bottom: 10px;
}

.cust-margin-5 {
    margin: 5px;
}

.cust-margin-3 {
    margin: 3px;
}

.btn-margin-left {
    margin-left: 10px;
}

.margin-top-7 {
    margin-top: 7px;
}

.form-btn-margin-top {
    margin-top: 29px;
}

.post-bottom-border {
    border-style: none none solid;
    border-width: 0 0 1px;
    border-color: #D2D6DE;
}

.manifest-tab .btn-success .badge {
    color: #FFF;
    background: transparent;
    border: 2px solid white;
}

.active-loader>.overlay,
.overlay-wrapper>.overlay,
.active-loader>.loading-img,
.overlay-wrapper>.loading-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.active-loader .overlay,
.overlay-wrapper .overlay {
    z-index: 50;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
}

.active-loader .overlay>.fa,
.overlay-wrapper .overlay>.fa {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    color: #000;
    font-size: 30px;
}

.active-loader .active-loader-img {
    position: absolute;
    top: 40%;
    left: 50%;
}

.callout p {
    font-size: 16px;
}*/


/* End Common CSS */


/* Start CSS Loader*/


/*#overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    z-index: 9999;
    opacity: 0.8;
    filter: alpha(opacity=80);
}

#loading {
    position: absolute;
    top: 65px;
    left: 70px;
    margin: -28px 0 0 -25px;
}

#loading-text {
    position: absolute;
    top: 115px;
    left: 95px;
    margin: -28px 0 0 -25px;
    color: #FFF;
}

.loader-content {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    border-bottom: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}*/


/* End CSS Loader*/


/* Start Nav Menu*/


/*.navbar-nav>.notifications-menu>.dropdown-menu>li .menu1,
.navbar-nav>.messages-menu>.dropdown-menu>li .menu1,
.navbar-nav>.tasks-menu>.dropdown-menu>li .menu1 {
    max-height: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: hidden;
}

.navbar-nav>.notifications-menu>.dropdown-menu>li .menu1>li>a,
.navbar-nav>.messages-menu>.dropdown-menu>li .menu1>li>a,
.navbar-nav>.tasks-menu>.dropdown-menu>li .menu1>li>a {
    display: block;
    white-space: nowrap;
    border-bottom: 1px solid #f4f4f4;
}

.navbar-nav>.notifications-menu>.dropdown-menu>li .menu1>li>a:hover,
.navbar-nav>.messages-menu>.dropdown-menu>li .menu1>li>a:hover,
.navbar-nav>.tasks-menu>.dropdown-menu>li .menu1>li>a:hover {
    background: #f4f4f4;
    text-decoration: none;
}

.navbar-nav>.notifications-menu>.dropdown-menu>li .menu1>li>a {
    color: #444444;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
}

.navbar-nav>.notifications-menu>.dropdown-menu>li .menu1>li>a>.glyphicon,
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu1>li>a>.fa,
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu1>li>a>.ion {
    width: 20px;
}

.navbar-nav>.messages-menu>.dropdown-menu>li .menu1>li>a {
    margin: 0;
    padding: 10px 10px;
}

.navbar-nav>.messages-menu>.dropdown-menu>li .menu1>li>a>div>img {
    margin: auto 10px auto auto;
    width: 40px;
    height: 40px;
}

.navbar-nav>.messages-menu>.dropdown-menu>li .menu1>li>a>h4 {
    padding: 0;
    margin: 0 0 0 45px;
    color: #444444;
    font-size: 15px;
    position: relative;
}

.navbar-nav>.messages-menu>.dropdown-menu>li .menu1>li>a>h4>small {
    color: #999999;
    font-size: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

.navbar-nav>.messages-menu>.dropdown-menu>li .menu1>li>a>p {
    margin: 0 0 0 45px;
    font-size: 12px;
    color: #888888;
}

.navbar-nav>.messages-menu>.dropdown-menu>li .menu1>li>a:before,
.navbar-nav>.messages-menu>.dropdown-menu>li .menu1>li>a:after {
    content: " ";
    display: table;
}

.navbar-nav>.messages-menu>.dropdown-menu>li .menu1>li>a:after {
    clear: both;
}

.navbar-nav>.tasks-menu>.dropdown-menu>li .menu1>li>a {
    padding: 10px;
}

.navbar-nav>.tasks-menu>.dropdown-menu>li .menu1>li>a>h3 {
    font-size: 14px;
    padding: 0;
    margin: 0 0 10px 0;
    color: #666666;
}

.navbar-nav>.tasks-menu>.dropdown-menu>li .menu1>li>a>.progress {
    padding: 0;
    margin: 0;
}

.cust-menu-1 {
    height: 328px !important;
    max-height: 328px !important;
}
*/


/* End Nav Menu*/

.slimScrollBar {
    background: none repeat scroll 0 0 #3c8dbc !important;
    border-radius: 0;
    display: none;
    height: 702.936px;
    position: absolute;
    right: 1px;
    top: 145px;
    width: 5px!important;
    z-index: 99;
    opacity: 0.7!important;
}
